import { ref } from '@vue/composition-api'

import store from '@/store'

import useToast from '@useToast'

export default function useMessBoardHandle() {
  const { toastError } = useToast()
  const loading = ref(false)

  const getAllPins = () => store
    .dispatch('app-documents/getAllPins') // Giả sử action này trả về dữ liệu các pins
    .then(response => response.data || [])
    .catch(() => {
      toastError({
        title: 'pinsList.error',
        content: 'document.pins.fetch.error',
      })
    })

  const getDocumentById = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-documents/getDocumentById', id)
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'document.message.fetch.error',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  return {
    // fetchAllMessBoards,
    loading,
    getAllPins,
    getDocumentById,
  }
}
