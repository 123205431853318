<template>
  <div>
    <!-- Tabs with filter buttons at the top -->
    <b-tabs
      content-class="tabs-content-note"
      class="tabs-container"
    >
      <template #tabs-end>
        <div class="d-flex flex-1 justify-content-end gap-2">
          <span class="d-flex text-warning align-items-center justify-content-end ">{{ $t(`messBoard.sort.${sortOrder}`) }}</span>
          <b-button
            pill
            class="p-50"
            variant="flat-warning"

            @click="sortMessages()"
          >
            <IAmIcon
              icon="sortOutline"
              size="22"
            />
          </b-button>
          <b-button
            v-for="(filterValue, index) in filterValues"
            :key="index"
            pill
            variant="outline-warning"
            class="px-50 py-0"
            style="transition: transform 0.3s ease, background-color 0.3s ease"
            @click="setFilter(filterValue.value)"
          >
            {{ $t(`messBoard.name.${filterValue.name}`) }}
          </b-button>
        </div>
      </template>
      <!-- Tab content sections -->
      <div>
        <b-tab active>
          <template #title>
            <span class="font-medium-4 font-weight-bold">
              {{ $t('messBoard.note') }}
            </span>
            <span class="font-small-2 ">({{ filterMessage?.length }})</span>
          </template>
          <TabsNoteMessage
            :messageAllBoard="filterMessage"
            @toggle-content="handleToggleContent"
          />
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <span class="tab-title">
              {{ $t('notification.title') }}
            </span>
            <span class="quantity-mess">({{ filterMessage.length }})</span>
          </template>
        </b-tab>

        <b-tab>
          <template #title>
            <span class="tab-title">
              {{ $t('messBoard.promotion') }}
            </span>
            <span class="quantity-mess">({{ filterMessage.length }})</span>
          </template>
        </b-tab> -->
      </div>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab, BButton,
} from 'bootstrap-vue'
import {
  ref, onMounted, onUnmounted, computed, set, watch, nextTick,
} from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import useMessBoardHandle from './useMessBoardHandle'
import documentStoreModule from '../documents/documentStoreModule'
import TabsNoteMessage from './TabsNoteMessage.vue'

export default {
  components: {
    TabsNoteMessage,
    BButton,
    BTabs,
    BTab,
  },
  setup() {
    const { route } = useRouter()
    const selectedFilter = ref('ALL')
    const filterValues = ref([
      {
        name: 'ALL', value: 'ALL',
      },
      {
        name: 'HIGH', value: 'HIGH',
      },
      {
        name: 'MEDIUM', value: 'MEDIUM',
      },
      {
        name: 'LOW', value: 'LOW',
      },
    ])
    const MESS_BOARD_STORE_MODULE_NAME = 'app-documents'
    // ĐĂNg ký module
    if (!store.hasModule(MESS_BOARD_STORE_MODULE_NAME)) {
      store.registerModule(MESS_BOARD_STORE_MODULE_NAME, documentStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(MESS_BOARD_STORE_MODULE_NAME)) {
        store.unregisterModule(MESS_BOARD_STORE_MODULE_NAME)
      }
    })
    const {
      loading, getAllPins,
    } = useMessBoardHandle()
    const boards = ref([])
    const pins = ref()
    const sortOrder = ref('original')
    const originalBoards = ref([...boards.value])
    const sortMessages = () => {
      if (sortOrder.value === 'original') {
        sortOrder.value = 'asc'
        boards.value.sort((a, b) => {
          const priorityMap = { HIGH: 3, MEDIUM: 2, LOW: 1 }
          return (priorityMap[a.priorityPin] || 0) - (priorityMap[b.priorityPin] || 0) // Tăng dần
        })
      } else if (sortOrder.value === 'asc') {
        sortOrder.value = 'desc'
        boards.value.sort((a, b) => {
          const priorityMap = { HIGH: 3, MEDIUM: 2, LOW: 1 }
          return (priorityMap[b.priorityPin] || 0) - (priorityMap[a.priorityPin] || 0) // Giảm dần
        })
      } else {
        sortOrder.value = 'original'
        boards.value = [...originalBoards.value] // Trả về trạng thái ban đầu
      }
    }
    const setFilter = filterValue => {
      selectedFilter.value = filterValue
    }
    const filterMessage = computed(() => {
      if (selectedFilter.value === 'ALL') {
        return boards.value // Hiển thị ALL thông báo khi filter là 'ALL'
      }
      return boards.value.filter(message => message.priorityPin === selectedFilter.value)
    })
    const handleToggleContent = index => {
      const item = boards.value[index]

      if (!item) return
      set(item, 'isShow', !item.isShow)
      if (!item.isRead) {
        set(item, 'isRead', true)
      }
    }
    onMounted(async () => {
      pins.value = await getAllPins()
      boards.value = pins.value.items.map(item => ({
        ...item,
        isRead: false,
      }))
      originalBoards.value = [...boards.value]
    })

    watch(() => route.value.params.id, newId => {
      nextTick()
      // Tìm phần tử có id tương ứng và cuộn đến đó
      const targetElement = document.getElementById(`message-${newId}`)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
      setTimeout(() => {
        window.scrollBy(0, -50) // Cuộn lên trên 50px
      }, 500)
    }, { immediate: true })
    return {
      loading, boards, setFilter, filterValues, filterMessage, sortMessages, handleToggleContent, sortOrder,
    }
  },

}

</script>

<style scoped>

.filter-button {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #ff9900;
  transform: scale(1.05);
}

/* Add transition for button entry/exit */
.button-transition-enter-active,
.button-transition-leave-active {
  transition: opacity 0.3s ease;
}

.button-transition-enter, .button-transition-leave-to /* .button-transition-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
