<template>
  <div>
    <div
      v-for="(messBoard, index) in messageAllBoard"
      :id="`message-${messBoard.id}`"
      :key="index"
      class="messBoard-card"
    >
      <Transition name="fadeCard">
        <b-card :bg-variant=" getVariantColor(messBoard) ">
          <template #header>
            <div class="custom-title">
              <h3 class="mb-0">
                <span
                  class="font-weight-bold "
                  :style="{ color: getBgColor(messBoard.priorityPin) }"
                >({{ $t(`messBoard.priorityPin.${messBoard.priorityPin}`) }})</span> <span style="font-size: smaller; font-weight: 700;">{{ $t('messBoard.from') }} {{ mamaFullName }}</span>
              </h3>
            </div>
            <div>
              <span style="color: black">{{ dateTime(messBoard.updatedAt) }}</span>
            </div>
          </template>
          <b-card-text class="text-dark">
            <span class="nav-link font-medium-3 ">{{ messBoard.name }}</span>
            <Transition
              name="fade"
              mode="out-in"
            >
              <div
                v-if="expandedItems[index]"
                key="expanded"
                v-html="messBoard.description"
              />
              <div
                v-else
                key="truncated"
                class="clamped-content"
                v-html="truncateDescription(messBoard.description,messBoard.name)"
              />
            </Transition>
          </b-card-text>
          <!-- Nút "Xem thêm" / "Thu gọn" -->
          <div style="display: flex; justify-content: space-between;">
            <a
              :style="{ color: getBgColor(messBoard.priorityPin) }"
              href="#"
              class="see-more"
              @click.prevent="toggleContent(index)"
            >
              {{ expandedItems[index] ? `${$t('messBoard.collapse')}` : `${$t('messBoard.viewMore')}` }}
            </a>
            <a
              v-if="expandedItems[index]"
              :style="{ color: getBgColor(messBoard.priorityPin) }"
              class=""
              href="#"
              @click.prevent="handleDetailDocument(messBoard.id)"
            >
              {{ $t('messBoard.detail') }}
            </a>
          </div>
        </b-card>
      </Transition>
    </div>
  </div>
</template>
<script>
import { BCard, BCardText } from 'bootstrap-vue'
import {
  ref, onMounted, watch,
} from '@vue/composition-api'

import { useRouter } from '@/@core/utils/utils'
import { env } from '@/libs/env'

import { dateTime } from '@core/utils/filter'

export default {
  components: {
    BCardText, BCard,
  },
  props: {
    messageAllBoard: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mamaFullName = env.mamaFullName
    const { router } = useRouter()
    const expandedItems = ref([])

    const handleDetailDocument = id => {
      try {
        router.push({
          name: 'apps-documents-edit',
          params: { id },
        })
      } catch (error) {
        console.error('Error fetching document data:', error)
      }
    }
    const getBgColor = priorityPin => {
      switch (priorityPin) {
        case 'HIGH':
          return '#f44336'
        case 'MEDIUM':
          return '#ff9800'
        case 'LOW':
          return '#555555'
        default:
          return '#ffffff'
      }
    }
    const getVariantColor = noti => {
      if (noti.priorityPin === 'HIGH' && !noti.isRead) {
        return 'light-danger'
      } if (noti.priorityPin === 'MEDIUM' && !noti.isRead) {
        return 'light-warning'
      } if (noti.priorityPin === 'LOW' && !noti.isRead) {
        return 'light-secondary'
      }
      return 'light'
    }
    const truncateDescription = description => description
    onMounted(() => {
      if (props.messageAllBoard && Array.isArray(props.messageAllBoard.items)) {
        expandedItems.value = props.messageAllBoard.items.map(() => false)
      }
      if (props.messageAllBoard && Array.isArray(props.messageAllBoard.items)) {
        expandedItems.value = new Array(props.messageAllBoard.items.length).fill(false)
      }
    })

    watch(
      () => props.messageAllBoard,
      newItems => {
        if (!newItems.length) return

        expandedItems.value = newItems.map(item => {
          if (item.isShow) {
            return true
          }
          return false
        })
      },
      { deep: true, immediate: true },
    )

    // Khởi tạo expandedItems khi props được truyền vào
    if (props.messageAllBoard && Array.isArray(props.messageAllBoard.items)) {
      expandedItems.value = new Array(props.messageAllBoard.items.length).fill(false)
    }

    const toggleContent = index => {
      emit('toggle-content', index) // Phát sự kiện kèm theo index
    }

    return {
      getBgColor, truncateDescription, getVariantColor, handleDetailDocument, dateTime, toggleContent, expandedItems, mamaFullName,
    }
  },

}
</script>

<style  scoped>

.clamped-content{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: 100%;
}
.clamped-content img {
  display: none;
}
.card-image{
  width: 30%;
  border-radius: 10%;
  margin-bottom: 30px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0.1;
}

/* Các lớp cho imgFade */
.imgFade-enter-active, .imgFade-leave-active {
  transition: opacity 0.5s ease;
}
.imgFade-enter-from, .imgFade-leave-to {
  opacity: 0.1;
}

/* Các lớp cho slide-fadeCard */
.slide-fadeCard-enter-active, .slide-fadeCard-leave-active {
  transition: all .3s ease;
}
.slide-fadeCard-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fadeCard-enter, .slide-fadeCard-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
