var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.messageAllBoard, function (messBoard, index) {
    return _c('div', {
      key: index,
      staticClass: "messBoard-card",
      attrs: {
        "id": "message-".concat(messBoard.id)
      }
    }, [_c('Transition', {
      attrs: {
        "name": "fadeCard"
      }
    }, [_c('b-card', {
      attrs: {
        "bg-variant": _vm.getVariantColor(messBoard)
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "custom-title"
          }, [_c('h3', {
            staticClass: "mb-0"
          }, [_c('span', {
            staticClass: "font-weight-bold ",
            style: {
              color: _vm.getBgColor(messBoard.priorityPin)
            }
          }, [_vm._v("(" + _vm._s(_vm.$t("messBoard.priorityPin.".concat(messBoard.priorityPin))) + ")")]), _vm._v(" "), _c('span', {
            staticStyle: {
              "font-size": "smaller",
              "font-weight": "700"
            }
          }, [_vm._v(_vm._s(_vm.$t('messBoard.from')) + " " + _vm._s(_vm.mamaFullName))])])]), _c('div', [_c('span', {
            staticStyle: {
              "color": "black"
            }
          }, [_vm._v(_vm._s(_vm.dateTime(messBoard.updatedAt)))])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card-text', {
      staticClass: "text-dark"
    }, [_c('span', {
      staticClass: "nav-link font-medium-3 "
    }, [_vm._v(_vm._s(messBoard.name))]), _c('Transition', {
      attrs: {
        "name": "fade",
        "mode": "out-in"
      }
    }, [_vm.expandedItems[index] ? _c('div', {
      key: "expanded",
      domProps: {
        "innerHTML": _vm._s(messBoard.description)
      }
    }) : _c('div', {
      key: "truncated",
      staticClass: "clamped-content",
      domProps: {
        "innerHTML": _vm._s(_vm.truncateDescription(messBoard.description, messBoard.name))
      }
    })])], 1), _c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between"
      }
    }, [_c('a', {
      staticClass: "see-more",
      style: {
        color: _vm.getBgColor(messBoard.priorityPin)
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleContent(index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.expandedItems[index] ? "".concat(_vm.$t('messBoard.collapse')) : "".concat(_vm.$t('messBoard.viewMore'))) + " ")]), _vm.expandedItems[index] ? _c('a', {
      style: {
        color: _vm.getBgColor(messBoard.priorityPin)
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleDetailDocument(messBoard.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.detail')) + " ")]) : _vm._e()])], 1)], 1)], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }