import { render, staticRenderFns } from "./MessageBoard.vue?vue&type=template&id=cd81a318&scoped=true"
import script from "./MessageBoard.vue?vue&type=script&lang=js"
export * from "./MessageBoard.vue?vue&type=script&lang=js"
import style0 from "./MessageBoard.vue?vue&type=style&index=0&id=cd81a318&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd81a318",
  null
  
)

export default component.exports