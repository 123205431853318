var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-tabs', {
    staticClass: "tabs-container",
    attrs: {
      "content-class": "tabs-content-note"
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex flex-1 justify-content-end gap-2"
        }, [_c('span', {
          staticClass: "d-flex text-warning align-items-center justify-content-end "
        }, [_vm._v(_vm._s(_vm.$t("messBoard.sort.".concat(_vm.sortOrder))))]), _c('b-button', {
          staticClass: "p-50",
          attrs: {
            "pill": "",
            "variant": "flat-warning"
          },
          on: {
            "click": function click($event) {
              return _vm.sortMessages();
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "sortOutline",
            "size": "22"
          }
        })], 1), _vm._l(_vm.filterValues, function (filterValue, index) {
          return _c('b-button', {
            key: index,
            staticClass: "px-50 py-0",
            staticStyle: {
              "transition": "transform 0.3s ease, background-color 0.3s ease"
            },
            attrs: {
              "pill": "",
              "variant": "outline-warning"
            },
            on: {
              "click": function click($event) {
                return _vm.setFilter(filterValue.value);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("messBoard.name.".concat(filterValue.name))) + " ")]);
        })], 2)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        var _vm$filterMessage;
        return [_c('span', {
          staticClass: "font-medium-4 font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.$t('messBoard.note')) + " ")]), _c('span', {
          staticClass: "font-small-2 "
        }, [_vm._v("(" + _vm._s((_vm$filterMessage = _vm.filterMessage) === null || _vm$filterMessage === void 0 ? void 0 : _vm$filterMessage.length) + ")")])];
      },
      proxy: true
    }])
  }, [_c('TabsNoteMessage', {
    attrs: {
      "messageAllBoard": _vm.filterMessage
    },
    on: {
      "toggle-content": _vm.handleToggleContent
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }